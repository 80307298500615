<template>
  <el-row id="app_navbar" justify="space-between" align="middle" :gutter="20">
    <el-affix v-if="IsSmallScreen" :offset="0" position="top">
      <el-button
        onlyIcon
        v-if="IsSmallScreen"
        type="primary"
        @click="changeMenu(!isCollapsed)"
        :icon="!isCollapsed ? 'el el-icon-close' : 'el el-icon-s-operation'"
      ></el-button>
    </el-affix>
    <el-col :xs="6" :sm="4" :md="6">
      <el-row justify="start">
        <el-image
          style="width: 200px; height: 60px"
          :src="'/images/logo.png'"
          :fit="'contain'"
        ></el-image>
      </el-row>
    </el-col>
    <el-col :xs="0" :sm="12" :md="12">
      <el-input
        placeholder="Buscar clientes, produtos ou fornecedores..."
        v-model="inputSearch"
        clearable
        @keyup.enter="$store.commit('update-search', inputSearch)"
        class="input-with-select"
      >
        <template #append>
          <el-button
            icon="el-icon-search"
            @click="$store.commit('update-search', inputSearch)"
          ></el-button>
        </template>
      </el-input>
    </el-col>
    <el-col :xs="14" :sm="4" :md="6">
      <el-row type="flex" justify="end" align="middle">
        <el-dropdown>
          <el-button
            size="medium"
            type="text"
            style="color: black"
            class="black-text-button"
          >
            {{ userName }}
          </el-button>
          <template #dropdown>
            <el-dropdown-menu style="width: 150px">
              <el-dropdown-item icon="el-icon-s-comment">
                <el-badge class="item"> Mensagens </el-badge>
              </el-dropdown-item>
              <el-dropdown-item icon="el-icon-message-solid">
                <el-badge :max="99" class="item"> Notificações </el-badge>
              </el-dropdown-item>
              <router-link to="/agenda">
                <el-dropdown-item icon="el-icon-phone">Agenda</el-dropdown-item>
              </router-link>
              <el-dropdown-item icon="el-icon-check"
                >Meu perfil</el-dropdown-item
              >

              <el-dropdown-item
                @click="logoffUser()"
                icon="el-icon-switch-button"
                >Sair</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: "navbar",
  data: () => ({
    inputSearch: "",
  }),
  props: ["isCollapsed"],
  methods: {
    changeMenu(v) {
      this.$emit("change-menu", v);
    },
    logoffUser() {
      this.$store.dispatch("logoffUser");
    },
  },
  watch: {
    inputSearch(v) {
      if (!v?.length) this.$store.commit("update-search", v);
    },
  },
  computed: {
    IsSmallScreen() {
      return window.innerWidth <= 768;
    },
    userName() {
      return this.$store?.state?.user?.shortname || "Usuário";
    },
  },
};
</script>
<style scoped>
sup.is-fixed {
  padding-top: 8px;
  padding-right: 0px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #efebe9;
}

.black-text-button {
  display: inline-block;
  height: 100%;
  color: #888;
}

.el-row {
  height: 100%;
}

.logo-card {
  height: 50px;
  display: flex;
}

@media print {
  #app_navbar {
    display: none;
  }
}
</style>
