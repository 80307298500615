<template>
  <el-menu
    id="app_menu"
    default-active="5"
    active-text-color="#409EFF"
    :unique-opened="true"
    :collapse="isCollapsed"
    :class="{
      'el-menu-overflow': IsSmallScreen && !isCollapsed,
    }"
  >
    <router-link replace to="/inicio"
      ><el-menu-item index="1">
        <i class="el-icon-s-home"></i>
        <template #title>Início</template>
      </el-menu-item></router-link
    >
    <el-sub-menu
      index="1"
      v-if="canAny('CreateOrder', 'UpdateOrder', 'AcceptOrder')"
    >
      <template #title>
        <i class="el-icon-office-building"></i>
        <span>Fabrica</span>
      </template>

      <el-sub-menu index="1-2" v-if="canAny('CreateOrder', 'UpdateOrder')">
        <template #title><span>Pré-Pedido</span></template>
        <router-link replace to="/pre-pedido" v-if="canAny('CreateOrder')"
          ><el-menu-item index="1-2-1">Cadastro</el-menu-item></router-link
        >
        <router-link
          replace
          to="/producao"
          v-if="canAny('FinalizeProductionOrder')"
        >
          <el-menu-item index="1-2-2">Produção</el-menu-item>
        </router-link>
        <router-link replace to="/analise-pedidos" v-if="canAny('AcceptOrder')">
          <el-menu-item index="1-2-4">Análise</el-menu-item></router-link
        >
        <router-link
          replace
          to="/conferencia-pedidos"
          v-if="canAny('CheckOrder')"
        >
          <el-menu-item index="1-2-3">Conferente</el-menu-item>
        </router-link>
        <router-link
          replace
          to="/pedidos-entrega"
          v-if="canAny('DeliverOrder')"
        >
          <el-menu-item index="1-2-6">Entrega</el-menu-item></router-link
        >
        <router-link
          replace
          to="/pedidos-finalizados"
          v-if="canAny('FinalizeOrder', 'ViewFinalizedOrders')"
        >
          <el-menu-item index="1-2-5">Finalizado</el-menu-item></router-link
        >
      </el-sub-menu>

      <el-sub-menu index="3-1" v-if="canAny('CreateOrderPayment')">
        <template #title>
           Caixa
        </template>
        <router-link
          replace
          to="/fabrica/caixa/cadastro"
        >
          <el-menu-item index="3-1-1">Cadastro</el-menu-item>
        </router-link>
        <router-link
          replace
          to="/fabrica/caixa/listagem"
        >
          <el-menu-item index="3-1-2">Lista</el-menu-item>
        </router-link>
      </el-sub-menu>

      <el-sub-menu
        index="1-3"
        v-if="canAny('FinalizeOrder', 'ViewFinalizedOrders')"
      >
        <template #title><span>Relatórios</span></template>
        <router-link
          v-if="canAny('ViewExecutiveReports')"
          to="/pedidos-grafico"
          replace
          ><el-menu-item index="1-3-100">Resultados</el-menu-item></router-link
        >
        <router-link
          v-if="canAny('ViewExecutiveReports')"
          to="/relatorio/pedidos/quantidades"
          replace
          ><el-menu-item index="1-3-1">Compra/Venda</el-menu-item></router-link
        >
        <router-link
          v-if="canAny('ViewExecutiveReports')"
          to="/relatorio/pedidos/top10"
          replace
          ><el-menu-item index="1-3-10">TOP 10</el-menu-item></router-link
        >
        <router-link
          v-if="canAny('ViewExecutiveReports')"
          to="/relatorio/pedidos/receita"
          replace
          ><el-menu-item index="1-3-55">Animais</el-menu-item></router-link
        >
        <router-link
          v-if="canAny('ViewExecutiveReports')"
          to="/relatorio/pedidos/resumo"
          replace
          ><el-menu-item index="1-3-11">Resumido</el-menu-item></router-link
        >
        <router-link
          v-if="canAny('ViewExecutiveReports')"
          to="/relatorios/pedidos/produtos"
          replace
        >
          <el-menu-item index="1-3-2">Por Produtos</el-menu-item>
        </router-link>
        <router-link replace to="/pedidos-diarios"
          ><el-menu-item index="1-3-3">Livro Diário</el-menu-item></router-link
        >
        <router-link
          replace
          to="/pedidos-relatorio"
          v-if="canAny('ViewExecutiveReports', 'ViewOperationalReports')"
          ><el-menu-item index="1-3-4">Pedidos</el-menu-item></router-link
        >
        <router-link replace to="/pedidos-pagamentos"
          ><el-menu-item index="1-3-7">Recebimentos</el-menu-item></router-link
        >
        <el-menu-item index="1-3-5" disabled>Pedido Empenho</el-menu-item>
      </el-sub-menu>
    </el-sub-menu>
    <el-sub-menu
      index="35"
      v-if="canAny('CheckOrderPayment', 'CreateOrderPayment')"
    >
      <template #title>
        <i class="el-icon-money"></i>
        <span>Cobrança</span></template
      >
      <router-link
        replace
        to="/baixar-pagamentos"
        v-if="canAny('CheckOrderPayment')"
        ><el-menu-item index="35-1">Baixar Pedidos</el-menu-item></router-link
      >
      <router-link
        replace
        to="/pedidos-cobranca"
        v-if="canAny('CreateOrderPayment')"
        ><el-menu-item index="35-2">Tickar</el-menu-item></router-link
      >
    </el-sub-menu>
    <el-menu-item index="2" v-if="false">
      <i class="el-icon-sell"></i>
      <template #title>Venda</template>
    </el-menu-item>
    <el-menu-item index="3" disabled v-if="false">
      <i class="el-icon-sold-out"></i>
      <template #title>Compra</template>
    </el-menu-item>
    <el-sub-menu
      index="4"
      v-if="
        canAny(
          'UpdatePackingList',
          'CreatePackingList',
          'AnalysePackingList',
          'DeletePackingList',
          'CreateCarcass',
          'UpdateCarcass',
          'DeleteCarcass'
        )
      "
    >
      <template #title>
        <i class="el-icon-s-management"></i>
        <span>Entradas</span></template
      >
      <router-link
        replace
        to="/produtos-entradas"
        v-if="
          canAny(
            'UpdateProductEntry',
            'CreateProductEntry',
            'DeleteProductEntry'
          )
        "
      >
        <el-menu-item index="4-10">Entrada Produtos</el-menu-item>
      </router-link>
      <router-link
        replace
        to="/carcacas"
        v-if="canAny('UpdateCarcass', 'CreateCarcass', 'DeleteCarcass')"
      >
        <el-menu-item index="4-1">Entrada Carcaça</el-menu-item>
      </router-link>
      <router-link
        replace
        to="/romaneio"
        v-if="
          canAny('UpdatePackingList', 'CreatePackingList', 'DeletePackingList')
        "
      >
        <el-menu-item index="4-2">Montagem Romaneio</el-menu-item></router-link
      >
      <router-link
        replace
        to="/entradas-notas"
        v-if="canAny('UpdatePurchases', 'CreatePurchases', 'DeletePurchases')"
      >
        <el-menu-item index="4-20">Montagem Notas</el-menu-item></router-link
      >
      <router-link
        replace
        to="/conferencia"
        v-if="
          canAny(
            'UpdateAnimal',
            'UpdateCarcass',
            'UpdatePackingList',
            'AnalysePackingList',
            'CheckPackingList'
          )
        "
      >
        <el-menu-item index="4-3">Conferência</el-menu-item></router-link
      >
      <el-sub-menu
        index="4-4"
        v-if="canAny('ViewExecutiveReports', 'ViewOperationalReports')"
        ><template #title> <span>Relatórios</span></template>
        <router-link replace to="/romaneio-relatorio">
          <el-menu-item index="4-4-1"> Romaneios </el-menu-item>
        </router-link>
        <router-link
          replace
          to="/animais-relatorio"
          v-if="canAny('ViewExecutiveReports')"
        >
          <el-menu-item index="4-4-2"> Animais </el-menu-item>
        </router-link>
        <router-link
          replace
          to="/animais-relatorio-por-qualidade"
          v-if="canAny('ViewExecutiveReports')"
        >
          <el-menu-item index="4-4-3"> Por qualidade </el-menu-item>
        </router-link>
        <router-link
          replace
          to="/entradas-relatorio"
          v-if="canAny('ViewOperationalReports')"
        >
          <el-menu-item index="4-4-4"> Produtos </el-menu-item>
        </router-link>
      </el-sub-menu>
    </el-sub-menu>
    <el-sub-menu
      index="15"
      v-if="
        canAny('UpdateCashierClose', 'DeleteCashierClose', 'CreateCashierClose')
      "
    >
      <template #title>
        <i class="el-icon-s-management"></i>
        <span>Caixa</span></template
      >
      <router-link
        replace
        to="/caixas-fechamento"
        v-if="canAny('CreateCashierClose')"
      >
        <el-menu-item index="15-1">Fechamento</el-menu-item>
      </router-link>
      <router-link
        replace
        to="/caixas-conferencia"
        v-if="
          canAny(
            'UpdateCashierClose',
            'DeleteCashierClose',
            'AcceptCashierClose'
          )
        "
      >
        <el-menu-item index="15-2">Conferência</el-menu-item>
      </router-link>
      <router-link
        replace
        to="/caixas-compras"
        v-if="
          canAny(
            'UpdateCashierClose',
            'DeleteCashierClose',
            'AcceptCashierClose'
          )
        "
      >
        <el-menu-item index="15-22">Compras</el-menu-item>
      </router-link>
      <el-sub-menu
        index="15-3"
        v-if="
          canAny(
            'UpdateCahiserClose',
            'DeleteCashierClose',
            'AcceptCashierClose',
            'FinalizeCashierClose'
          )
        "
      >
        <template #title> Relatórios </template>
        <router-link
          replace
          to="/caixas-relatorio"
          v-if="canAny('ViewExecutiveReports', 'ViewOperationalReports')"
        >
          <el-menu-item index="15-3-1" v-if="canAny('ViewOperationalReports')"
            >Listagem</el-menu-item
          >
        </router-link>
        <router-link
          replace
          to="/caixas-resultados"
          v-if="canAny('UpdateCashierClose', 'DeleteCashierClose')"
        >
          <el-menu-item index="15-3-2" v-if="canAny('ViewExecutiveReports')"
            >Resultados</el-menu-item
          >
        </router-link>
      </el-sub-menu>
    </el-sub-menu>
    <el-sub-menu
      index="5"
      v-if="
        canAny(
          'CreateUser',
          'UpdateUser',
          'DeleteUser',
          'CreateUserGroup',
          'UpdateUserGroup',
          'DeleteUserGroup'
        )
      "
    >
      <template #title>
        <i class="el-icon-user"></i>
        <span>Usuários</span></template
      >
      <router-link
        replace
        to="/usuarios"
        v-if="canAny('CreateUser', 'UpdateUser', 'DeleteUser')"
        ><el-menu-item index="5-1">Lista</el-menu-item></router-link
      >
      <router-link
        replace
        to="/grupos"
        v-if="canAny('CreateUserGroup', 'UpdateUserGroup', 'DeleteUserGroup')"
        ><el-menu-item index="5-2"
          >Grupos e Permissões</el-menu-item
        ></router-link
      >
      <el-menu-item index="5-3" disabled>Relatório</el-menu-item>
    </el-sub-menu>

    <el-sub-menu
      index="6"
      v-if="
        canAny(
          'CreateProduct',
          'UpdateProduct',
          'DeleteProduct',
          'CreateProductCategory',
          'UpdateProductCategory',
          'DeleteProductCategory',
          'CreateProductPrices',
          'UpdateProductPrices',
          'DeleteProductPrices'
        )
      "
    >
      <template #title>
        <i class="el-icon-s-goods"></i>
        <span>Produtos</span></template
      >
      <router-link
        replace
        to="/produtos"
        v-if="canAny('CreateProduct', 'UpdateProduct', 'DeleteProduct')"
        ><el-menu-item index="6-1">Lista</el-menu-item></router-link
      >
      <router-link
        replace
        to="/categorias"
        v-if="
          canAny(
            'CreateProductCategory',
            'UpdateProductCategory',
            'DeleteProductCategory'
          )
        "
        ><el-menu-item index="6-2">Categorias</el-menu-item></router-link
      >
      <router-link
        replace
        to="/tabela-precos"
        v-if="
          canAny(
            'CreateProductPrices',
            'UpdateProductPrices',
            'DeleteProductPrices'
          )
        "
        ><el-menu-item index="6-3">Tabela de Preços</el-menu-item></router-link
      >
      <router-link replace to="/codigos" v-if="canAny('UpdateProduct')"
        ><el-menu-item index="6-4"
          >Códigos do Produto</el-menu-item
        ></router-link
      >
      <router-link
        replace
        to="/produtos-vendas"
        v-if="canAny('CreateSell', 'UpdateSell', 'DeleteSell')"
        ><el-menu-item index="6-5">Vincular Vendas</el-menu-item></router-link
      >
      <router-link
        replace
        to="/produtos/receitas"
        v-if="canAny('ViewExecutiveReports')"
        ><el-menu-item index="6-6">Receita</el-menu-item></router-link
      >
    </el-sub-menu>

    <el-sub-menu
      index="7"
      v-if="
        canAny(
          'CreateFirmAccount',
          'UpdateFirmAccount',
          'CreateBillet',
          'UpdateBillet',
          'DeleteBillet'
        )
      "
    >
      <template #title>
        <i class="el-icon-s-goods"></i>
        <span>Boletos e NF</span></template
      >
      <router-link
        replace
        to="/carteiras"
        v-if="canAny('CreateFirmAccount', 'UpdateFirmAccount', 'CreateInvoice')"
        ><el-menu-item index="7-1">Carteiras</el-menu-item>
      </router-link>
      <router-link replace to="/boletos"
        ><el-menu-item index="7-2">Boletos</el-menu-item></router-link
      >
      <router-link replace to="/notas-fiscais" v-if="canAny('CreateInvoice')"
        ><el-menu-item index="7-3">NF</el-menu-item></router-link
      >
    </el-sub-menu>

    <el-sub-menu
      index="8"
      v-if="
        canAny(
          'CreatePartner',
          'UpdatePartner',
          'DeletePartner',
          'CreateRoutes',
          'UpdateRoutes',
          'DeleteRoutes',
          'CreateMargins',
          'UpdateMargins',
          'DeleteMargins'
        )
      "
    >
      <template #title>
        <i class="el-icon-s-custom"></i>
        <span>Parceiros</span></template
      >
      <router-link
        replace
        to="/clientes"
        v-if="canAny('CreatePartner', 'UpdatePartner', 'DeletePartner')"
        ><el-menu-item index="8-1">Cadastro</el-menu-item></router-link
      >
      <router-link
        replace
        to="/clientes-rotas/private"
        v-if="canAny('CreateRoutes', 'UpdateRoutes', 'DeleteRoutes')"
        ><el-menu-item index="8-2">Rotas</el-menu-item></router-link
      >
      <router-link
        replace
        to="/clientes-margem"
        v-if="canAny('CreateMargins', 'UpdateMargins', 'DeleteMargins')"
        ><el-menu-item index="8-3">Margens de Lucro</el-menu-item></router-link
      >
    </el-sub-menu>

    <el-sub-menu
      index="9"
      v-if="canAny('CreatePublicSell', 'CreateResourceReservation')"
    >
      <template #title>
        <i class="el-icon-s-order"></i>
        <span>Governo</span></template
      >
      <router-link
        to="/pre-pedido/licitacao"
        replace
        v-if="canAny('CreatePublicSell')"
        ><el-menu-item index="9-1-1">Pedidos</el-menu-item></router-link
      >
      <router-link to="/licitacoes" replace v-if="canAny('CreatePublicSell')"
        ><el-menu-item index="9-1">Licitações</el-menu-item></router-link
      >

      <router-link
        replace
        to="/clientes-rotas/public"
        v-if="canAny('CreateRoutes', 'UpdateRoutes', 'DeleteRoutes')"
        ><el-menu-item index="9-999">Rotas</el-menu-item></router-link
      >
      <router-link
        replace
        to="/licitacoes/recibos"
        v-if="canAny('CreateReceipt', 'UpdateReceipt', 'DeleteReceipt')"
      >
        <el-menu-item index="9-3">Recibos</el-menu-item></router-link
      >
    </el-sub-menu>

    <el-sub-menu
      index="99"
      v-if="canAny('CreateFarmDaily')"
    >
      <template #title>
        <i class="el-icon-s-order"></i>
        <span>Fazenda</span></template
      >
      <router-link
        to="/fazenda/escala-serviço"
        replace
        v-if="false"
        ><el-menu-item index="99-1-1">Escala de Serviço</el-menu-item></router-link
      >
      <router-link
        to="/fazenda/livro-diario"
        replace
        v-if="canAny('CreateFarmDaily')"
        ><el-menu-item index="99-1-1">Livro Diário</el-menu-item></router-link
      >
      
    </el-sub-menu>

    <el-sub-menu
      index="10"
      v-if="
        canAny(
          'CreateFirms',
          'UpdateFirms',
          'DeleteFirms',
          'CreateCosts',
          'UpdateCosts',
          'DeleteCosts',
          'CreateBills',
          'UpdateBills',
          'DeleteBills',
          'CreateCheck',
          'UpdateCheck',
          'DeleteCheck'
        )
      "
    >
      <template #title>
        <i class="el-icon-bank-card"></i>
        <span>Financeiro</span></template
      >
      <router-link
        replace
        to="/empresas"
        v-if="canAny('CreateFirms', 'UpdateFirms', 'DeleteFirms')"
        ><el-menu-item index="10-1">Empresas</el-menu-item></router-link
      >
      <router-link
        replace
        to="/centro-custos"
        v-if="canAny('CreateCosts', 'UpdateCosts', 'DeleteCosts')"
        ><el-menu-item index="10-2">Centro Custo</el-menu-item></router-link
      >
      <router-link
        replace
        to="/contas"
        v-if="canAny('CreateBills', 'UpdateBills', 'DeleteBills')"
        ><el-menu-item index="10-3">Contas</el-menu-item></router-link
      >
      <router-link
        replace
        to="/cheques/emitidos"
        v-if="canAny('CreateCheck', 'UpdateCheck', 'DeleteCheck')"
        ><el-menu-item index="10-4">Cheques</el-menu-item></router-link
      >
      <el-sub-menu
        index="10-5"
        v-if="canAny('ViewExecutiveReports', 'ViewOperationalReports')"
      >
        <template #title> Relatórios </template>
        <router-link
          replace
          to="/contas-listagem"
          v-if="canAny('ViewOperationalReports')"
          ><el-menu-item index="10-5-1">Listagem</el-menu-item></router-link
        >
        <router-link
          replace
          to="/contas-centro-custos"
          v-if="canAny('ViewOperationalReports')"
          ><el-menu-item index="10-5-3"
            >Por centro de custo</el-menu-item
          ></router-link
        >
        <router-link
          replace
          to="/contas-vencimento-no-dia"
          v-if="canAny('ViewOperationalReports')"
          ><el-menu-item index="10-5-2">Por dia</el-menu-item></router-link
        >
      </el-sub-menu>
    </el-sub-menu>

    <el-sub-menu index="25" v-if="canAny('ViewExecutiveReports')">
      <template #title>
        <i class="el-icon-s-marketing"></i> <span>Contabilidade</span></template
      >
      <router-link
        replace
        to="/contabilidade-contas"
        v-if="canAny('ViewExecutiveReports')"
        ><el-menu-item index="25-1">Por contas</el-menu-item></router-link
      >
      <router-link
        replace
        to="/contabilidade/relatorio"
        v-if="canAny('ViewExecutiveReports')"
        ><el-menu-item index="25-2">Mensal</el-menu-item></router-link
      >
    </el-sub-menu>

    <el-sub-menu index="11" disabled v-if="false">
      <template #title>
        <i class="el-icon-notebook-2"></i>
        <span>Contabilidade</span></template
      >
      <el-menu-item index="11-1">Por Contas</el-menu-item>
      <el-menu-item index="11-2">Por Entrada</el-menu-item>
      <el-menu-item index="11-3">Relatório</el-menu-item>
    </el-sub-menu>

    <el-sub-menu
      index="20"
      v-if="
        canAny(
          'CreateEmployees',
          'UpdateEmployees',
          'DeleteEmployees',
          'CreateRole',
          'UpdateRole',
          'DeleteRole',
          'UpdateEmployeeDebt',
          'DeleteEmployeeDebt',
          'CreateEmployeeDebt',
          'UpdateEmployeeFile',
          'DeleteEmployeeFile',
          'CreateEmployeeFile'
        )
      "
    >
      <template #title>
        <i class="el-icon-s-cooperation"></i>
        <span>RH</span></template
      >
      <router-link
        replace
        to="/cargos"
        v-if="canAny('CreateRole', 'UpdateRole', 'DeleteRole')"
        ><el-menu-item index="20-1">Cargos e Funções</el-menu-item></router-link
      >
      <router-link
        replace
        to="/funcionarios"
        v-if="canAny('CreateEmployees', 'UpdateEmployees', 'DeleteEmployees')"
        ><el-menu-item index="20-2">Funcionários</el-menu-item></router-link
      >
      <router-link replace to="/folha"
        ><el-menu-item index="20-3"
          >Folha de Pagamento</el-menu-item
        ></router-link
      >
      <router-link
        replace
        to="/funcionarios/convenios"
        v-if="
          canAny(
            'UpdateEmployeeDebt',
            'DeleteEmployeeDebt',
            'CreateEmployeeDebt'
          )
        "
        ><el-menu-item index="20-4">Convênios</el-menu-item></router-link
      >
      <router-link
        replace
        v-if="canAny('UpdateSeller', 'CreateSeller', 'DeleteSeller')"
        to="/vendedores"
      >
        <el-menu-item index="20-6">Vendedores</el-menu-item>
      </router-link>
      <router-link
        replace
        to="/funcionarios/vales"
        v-if="
          canAny(
            'UpdateEmployeeDebt',
            'DeleteEmployeeDebt',
            'CreateEmployeeDebt'
          )
        "
        ><el-menu-item index="20-5">Vales</el-menu-item></router-link
      >
      <router-link replace to="/funcionarios" v-if="false"
        ><el-menu-item index="20-6">Faltas</el-menu-item></router-link
      >
    </el-sub-menu>

    <el-sub-menu
      index="12"
      v-if="
        canAny(
          'CreateDrivers',
          'UpdateDrivers',
          'DeleteDrivers',
          'CreateCars',
          'IndexCars',
          'UpdateCars',
          'DeleteCars',
          'ShowCars',
          'CreateCarSupply',
          'UpdateCarSupply',
          'DeleteCarSupply',
          'UpdateCarObs',
          'CreateCarObs',
          'DeleteCarObs',
          'CreateLogBook'
        )
      "
    >
      <template #title>
        <i class="el-icon-notebook-2"></i>
        <span>Frota</span></template
      >
      <router-link
        replace
        to="/motoristas"
        v-if="canAny('CreateDrivers', 'UpdateDrivers', 'DeleteDrivers')"
        ><el-menu-item index="12-2">Motoristas</el-menu-item></router-link
      >
      <router-link
        replace
        to="/veiculos"
        v-if="
          canAny(
            'CreateCars',
            'IndexCars',
            'UpdateCars',
            'DeleteCars',
            'DeleteCarObs'
          )
        "
        ><el-menu-item index="12-3">Veículos</el-menu-item></router-link
      >
      <router-link
        replace
        to="/veiculos-controle"
        v-if="
          canAny(
            'CreateCars',
            'IndexCars',
            'UpdateCars',
            'DeleteCars',
            'DeleteCarObs'
          )
        "
        ><el-menu-item index="12-30">Controles</el-menu-item></router-link
      >
      <router-link
        replace
        to="/veiculos-controle-executar"
        v-if="canAny('UpdateCarObs', 'CreateCarObs', 'CreateLogBook')"
        ><el-menu-item index="12-31"
          >Registro de bordo</el-menu-item
        ></router-link
      >
      <router-link
        replace
        to="/abastecimentos"
        v-if="canAny('CreateCarSupply', 'UpdateCarSupply', 'DeleteCarSupply')"
        ><el-menu-item index="12-4">Abastecimentos</el-menu-item></router-link
      >
      <router-link
        replace
        to="/veiculos-consumo"
        v-if="canAny('ViewExecutiveReports')"
        ><el-menu-item index="12-5">Relatório</el-menu-item></router-link
      >
    </el-sub-menu>

    <el-sub-menu
      index="13"
      v-if="
        canAny(
          'UpdateSystemPreference',
          'DeleteSystemPreference',
          'CreateSystemPreference',
          'RecoverTrash',
          'RemoveTrash',
          'IndexTrash'
        )
      "
    >
      <template #title>
        <i class="el-icon-setting"></i>
        <span>Sistema</span></template
      >
      <router-link
        replace
        to="/sistema-lixeira"
        v-if="canAny('RecoverTrash', 'RemoveTrash', 'IndexTrash')"
        ><el-menu-item index="13-1">Lixeira</el-menu-item></router-link
      >
      <router-link
        replace
        to="/sistema-configuracoes"
        v-if="
          canAny(
            'UpdateSystemPreference',
            'DeleteSystemPreference',
            'CreateSystemPreference'
          )
        "
        ><el-menu-item index="13-2">Configurações</el-menu-item></router-link
      >
      <router-link
        replace
        to="/sistema-logs"
        v-if="
          canAny(
            'UpdateSystemPreference',
            'DeleteSystemPreference',
            'CreateSystemPreference'
          )
        "
      >
        <el-menu-item index="13-3">Logs</el-menu-item></router-link
      >
    </el-sub-menu>

    <el-sub-menu
      index="14"
      v-if="
        canAny(
          'CreateBid',
          'DeleteBid',
          'UpdateBid',
          'CreateSent',
          'UpdateSent',
          'DeleteSent'
        )
      "
    >
      <template #title>
        <i class="el-icon-user-solid"></i>
        <span>Produtores</span></template
      >
      <router-link replace to="/contatos"
        ><el-menu-item index="14-1">Contatos</el-menu-item></router-link
      >
      <router-link
        replace
        to="/ofertas"
        v-if="canAny('CreateBid', 'DeleteBid', 'UpdateBid')"
      >
        <el-menu-item index="14-2">Ofertas</el-menu-item></router-link
      >
      <router-link
        replace
        to="/ofertas-enviadas"
        v-if="canAny('CreateSent', 'UpdateSent', 'DeleteSent')"
      >
        <el-menu-item index="14-3">Envios</el-menu-item></router-link
      >
    </el-sub-menu>
  </el-menu>
</template>

<script>
export default {
  name: "SideBar",
  props: ["isCollapsed"],
  computed: {
    IsSmallScreen() {
      return window.innerWidth <= 768;
    },
    can() {
      return this.$store.state.auth.can;
    },
  },
  methods: {
    canAny() {
      for (let a in arguments) if (this.can(arguments[a])) return true;

      return false;
    },
    canAll() {
      for (let a in arguments) if (!this.can(arguments[a])) return false;

      return true;
    },
  },
};
</script>

<style scoped>
.el-menu:not(.el-menu--collapse) {
  position: relative;
  width: 200px;

  overflow: visible;
  z-index: 99;
}

.el-menu-overflow {
  position: absolute !important;
}

.el-menu--collapse {
  position: absolute;
  overflow: hidden;

  z-index: 99;
}

@media print {
  #app_menu {
    display: none;
  }

  body {
    background-color: white;
  }
}
</style>
