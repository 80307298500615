<template>
  <el-container class="stretch-height">
    <el-header id="navbarApp">
      <navbar
        :key="navbarKey"
        :isCollapsed="isCollapsed"
        @change-menu="isCollapsed = $event"
      ></navbar>
    </el-header>
    <el-container>
      <side-bar
        :key="sidebarKey"
        :isCollapsed="isCollapsed"
        @change-menu="isCollapsed = $event"
      ></side-bar>
      <el-container>
        <el-main
          id="app_main"
          :key="mainKey"
          :class="{
            'collapsed-padding': isSmallScreen(),
            'stretch-height': true,
          }"
        >
          <router-view></router-view>
        </el-main>
        <el-footer>
          <dashboard-footer></dashboard-footer>
        </el-footer>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import SideBar from "./sidebar.vue";
import Navbar from "./navbar.vue";
import { uuid } from "vue-uuid";
import DashboardFooter from "./footer.vue";
import "@/assets/styles/common.scss";

export default {
  name: "Dashboard",
  data() {
    return {
      isCollapsed: window.innerWidth <= 768,
      sidebarKey: uuid.v4(),
      mainKey: uuid.v4(),
      navbarKey: uuid.v4(),
      mainWidth: window.innerWidth,
    };
  },
  mounted() {
    const observer = new ResizeObserver(() => {
      this.isCollapsed = window.innerWidth <= 768;
      if (window.innerWidth != this.mainWidth) {
        this.mainWidth = window.innerWidth;
        this.rebuildComponents();
      }
    });

    observer.observe(document.getElementById("navbarApp"));
  },
  components: {
    SideBar,
    Navbar,
    DashboardFooter,
  },
  methods: {
    rebuildComponents() {
      this.sidebarKey = uuid.v4();
      this.navbarKey = uuid.v4();
      this.mainKey = uuid.v4();
    },
    isSmallScreen() {
      return window.innerWidth <= 768;
    },
  },
};
</script>
<style>
.el-header,
.el-footer {
  background-color: #efebe9;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.collapsed-padding {
  padding-left: 65px !important;
}

.expanded-margin {
  margin-left: 150px !important;
}

.stretch-height {
  min-height: 100%;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  margin-left: 0px;
  height: 100%;
}

@media print {
  #app_main {
    background-color: white !important;
  }
}
</style>
